<template>
  <div class="m-settings">
    <div class="columns">
      <div class="column is-one-fifth">
        <section class="section">
          <div class="container">
            <aside class="b-menu">
              <b-menu>
                <b-menu-list :label="$t('generic.actions')">
                  <b-menu-item
                    :label="$t('settings.general')"
                    tag="router-link"
                    :to="{ name: 'settings-general' }"
                  ></b-menu-item>
                  <b-menu-item :label="$t('settings.roles')" tag="router-link" :to="{ name: 'settings-roles-list' }">
                    <b-menu-item
                      :label="$t('settings.createRole')"
                      tag="router-link"
                      :to="{ name: 'settings-role-new' }"
                    ></b-menu-item>
                  </b-menu-item>
                  <b-menu-item
                    :label="$t('settings.pushSubscriptions')"
                    tag="router-link"
                    :to="{ name: 'settings-push-list' }"
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </aside>
          </div>
        </section>
      </div>
      <div class="column">
        <section class="section">
          <div class="container">
            <router-view></router-view>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings'
};
</script>
